<template>
    <div>
        <div v-for="item in stepContentList" :key="item.text">
            <p class="my-4" v-html="$t(`faqTab.gettingStarted.paypal.${item.text}`, { 'paypal-link': paypalLink })" />
            <img class="w-100" :src="item.image" :alt="$t(`faqTab.gettingStarted.paypal.${item.text}`)">
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            paypalLink: "https://www.paypal.com/",
            stepContentList: [
                {
                    image: require("../assets/step0.1.png"),
                    text: "starting"
                },
                {
                    image: require("../assets/step0.2.png"),
                    text: "moved"
                }, 
                {
                    image: require("../assets/step0.3.png"),
                    text: "choose"
                }, 
                {
                    image: require("../assets/step0.4.png"),
                    text: "asked"
                }, 
                {
                    image: require("../assets/step0.5.png"),
                    text: "receive"
                }, 
                {
                    image: require("../assets/step0.6.png"),
                    text: "after"
                }, 
                {
                    image: require("../assets/step0.7.png"),
                    text: "askYou"
                },
            ]
        }
    }
}
</script>